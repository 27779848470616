import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { ReactNode } from 'react';
import ContentContainer from '~/src/components/ContentContainer';
import Tabs from '~/src/components/Tabs';
import './PageHeader.scss';

const PageHeader = ({
	children,
	tabs = [],
	tabRoot,
	ctaText,
	ctaUrl,
}: {
	children: ReactNode;
	tabs?: (string | string[])[];
	tabRoot?: string;
	ctaText?: string;
	ctaUrl?: string;
}) => (
	<div className="PageHeader">
		<ContentContainer>
			<div className="PageHeaderContainer">
				<h1>{children}</h1>
				{!!ctaText && (
					<div className="PageHeaderCta">
						<a href={ctaUrl}>
							{ctaText} <FontAwesomeIcon icon={faArrowRight} />
						</a>
					</div>
				)}
			</div>
			{tabs.length > 0 ? <Tabs links={tabs} root={tabRoot} /> : null}
		</ContentContainer>
	</div>
);

export default PageHeader;
