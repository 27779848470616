import React from 'react';
import { Ot7Team } from '~/src/models/Ot7Team';
import './Logo.scss';

const Logo = ({ team }: { team: Ot7Team }) => (
	<a href={`/teams/${team.id}`} className="TeamsLogo">
		{team?.small_logo_path ? (
			<img src={`https://images.overtime.tv/${team?.small_logo_path}?width=200`} />
		) : team?.logo_path ? (
			<img src={`https://images.overtime.tv/${team?.logo_path}?width=200`} />
		) : (
			<div className="Placeholder">{team?.team_code?.[0] ?? team?.name.slice(0, 1)}</div>
		)}
	</a>
);

export default Logo;
