import { startCase } from 'lodash';
import React from 'react';
import './Button.scss';

const Button = ({ children, href, inverted = false, size = 'large', ...rest }) => (
	<a
		className={['Button', inverted ? 'Inverted' : null, startCase(size)].filter(Boolean).join(' ')}
		href={href}
		{...rest}
	>
		<div>{children}</div>
	</a>
);

export default Button;
