import { SizeProp } from '@fortawesome/fontawesome-svg-core';
import { faInstagram, faTiktok, faTwitter } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Ot7Team } from '~/src/models/Ot7Team';
import './Links.scss';

const TeamsLinks = ({ team, size = '1x' }: { team: Ot7Team; size?: SizeProp }) => (
	<div className="TeamsLinks">
		{team.instagram_url ? (
			<a href={team.instagram_url}>
				<FontAwesomeIcon icon={faInstagram} size={size} />
			</a>
		) : null}
		{team.twitter_url ? (
			<a href={team.twitter_url}>
				<FontAwesomeIcon icon={faTwitter} size={size} />
			</a>
		) : null}
		{team.tiktok_url ? (
			<a href={team.tiktok_url}>
				<FontAwesomeIcon icon={faTiktok} size={size} />
			</a>
		) : null}
	</div>
);

export default TeamsLinks;
