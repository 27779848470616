import { faStoreAlt, faTicket } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext } from 'react';
import ContentContainer from '~/src/components/ContentContainer';
import { imgOt7NamemarkSvg } from '~/src/images';
import MobileMenu, { MobileMenuContext } from '~/src/overtime-lib/src/www/components/MobileMenu';
import './Header.scss';

const MenuItems = () => {
	const { isVisible } = useContext(MobileMenuContext);
	return (
		<nav>
			<a href="/schedule" className="mobile-only" tabIndex={!isVisible ? -1 : null}>
				Schedule
			</a>
			<a href="/teams" className="mobile-only" tabIndex={!isVisible ? -1 : null}>
				Teams
			</a>
			<a href="/events" className="mobile-only" tabIndex={!isVisible ? -1 : null}>
				Events
			</a>
			<a href="/standings" className="mobile-only" tabIndex={!isVisible ? -1 : null}>
				Standings
			</a>
			<a href="/about" className="mobile-only" tabIndex={!isVisible ? -1 : null}>
				About
			</a>
		</nav>
	);
};

const Header = () => {
	return (
		<header className="Header">
			<div className="Background" />
			<ContentContainer>
				<div className="HeaderContainer">
					<a className="Logo" href="/" title="Home" tabIndex={1}>
						<img src={imgOt7NamemarkSvg} />
					</a>
					<div className="LinksContainer">
						<div className="DesktopLinks">
							<a className="inherit" href="/schedule">
								Schedule
							</a>
							<a className="inherit" href="/teams">
								Teams
							</a>
							<a className="inherit" href="/events">
								Events
							</a>
							<a className="inherit" href="/standings">
								Standings
							</a>
							<a className="inherit" href="/about">
								About
							</a>
						</div>
					</div>
					<div className="LinksContainer">
						<a className="inherit Tickets" href="https://tickets.itsovertime.com">
							<FontAwesomeIcon icon={faTicket} />
							Tickets
						</a>
						<a className="inherit Shop" href="https://shop.overtime.tv">
							<FontAwesomeIcon icon={faStoreAlt} />
							Shop
						</a>
					</div>
					<MobileMenu>
						<MenuItems />
					</MobileMenu>
				</div>
			</ContentContainer>
		</header>
	);
};
export default Header;
