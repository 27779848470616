import React, { ReactNode } from 'react';
import './Eyebrow.scss';

const Eyebrow = ({ children, fullWidth = false }: { children: ReactNode; fullWidth?: boolean }) => (
	<div className={['Eyebrow', fullWidth ? 'FullWidth' : null].filter(Boolean).join(' ')}>
		<div className="EyebrowText">{children}</div>
	</div>
);

export default Eyebrow;
