import React from 'react';
import _NotFound from '~/src/components/NotFound';
import Layout from '../Layout';
import './NotFound.scss';

export default function NotFound() {
	return (
		<Layout>
			<_NotFound />
		</Layout>
	);
}
