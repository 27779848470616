import { isNil } from 'lodash';
import React from 'react';
import './Table.scss';

interface TableHeader {
	value?: string;
	label: string;
}
const Table = ({
	headers,
	currentHeader,
	onHeaderSelect,
	rows,
	firstColumnHeader,
}: {
	headers: TableHeader[];
	currentHeader: string;
	onHeaderSelect?: (string) => void;
	rows: JSX.Element[];
	firstColumnHeader?: string;
}) => (
	<div className="Table">
		<table>
			<thead>
				<tr>
					<th
						role={!isNil(headers[0]?.value) ? 'button' : undefined}
						onClick={() => {
							window.history.replaceState({}, document.title, location.pathname);
							onHeaderSelect && onHeaderSelect(null);
						}}
					>
						{firstColumnHeader}
					</th>
					{headers.map((h) => (
						<th
							key={h.value ?? h.label}
							role={!isNil(h?.value) ? 'button' : undefined}
							onClick={() => {
								if (isNil(h.value)) {
									return;
								}
								window.history.replaceState({}, document.title, location.pathname + '#' + h.value);
								onHeaderSelect(h.value);
							}}
							className={currentHeader === h.value ? 'current' : null}
						>
							{h.label}
						</th>
					))}
				</tr>
			</thead>
			<tbody>{rows}</tbody>
		</table>
	</div>
);

export default Table;
