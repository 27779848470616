import { isNil } from 'lodash';
import { DateTime } from 'luxon';
type RGB = `rgb(${number}, ${number}, ${number})`;
type RGBA = `rgba(${number}, ${number}, ${number}, ${number})`;
type HEX = `#${string}`;

export type Color = RGB | RGBA | HEX;
export type KeyOfType<T, V> = keyof {
	[P in keyof T as T[P] extends V ? P : never]: any;
};
export const colorFromInt = (value: number) =>
	(!isNil(value) ? `#${value.toString(16).padStart(6, '0')}` : null) as Color;
export const capitalize = (text: string) => text[0].toLocaleUpperCase() + text.substring(1);
export const positions = { C: 'Center', F: 'Forward', PF: 'Forward', G: 'Guard', PG: 'Guard' };
export const getEstDateTime = (iso?: string) =>
	DateTime.fromISO(iso ?? new Date().toISOString()).setZone(
		typeof window === 'undefined' ? 'America/New_York' : DateTime.local().zoneName,
	);
export const getDateTimeWithTimezone = (iso: string, timezone: string) => DateTime.fromISO(iso).setZone(timezone);

// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/PluralRules/PluralRules#examples
const pr = new Intl.PluralRules('en-US', { type: 'ordinal' });
const suffixes = new Map([
	['one', 'st'],
	['two', 'nd'],
	['few', 'rd'],
	['other', 'th'],
]);
export const formatOrdinals = (n: number) => {
	if (isNil(n)) {
		return undefined;
	}
	const rule = pr.select(n);
	const suffix = suffixes.get(rule);
	return `${n}${suffix}`;
};
