import React, { useContext, useEffect } from 'react';
import {
	imgAppleTouchIconPng,
	imgFaviconSvg,
	imgFavicon_16x16Png,
	imgFavicon_32x32Png,
	imgSafariPinnedTabSvg,
} from '~/src/images';
import config from '~/src/lib/config';
import styles from '~/src/lib/styles.json';
import CookieConsent from '~/src/overtime-lib/src/www/components/CookieConsent';
import DocumentContext from '~/src/overtime-lib/src/www/contexts/Document';
import './Html.scss';

export default function Html({
	assets = {},
	children = null,
}: {
	assets: { [key: string]: any };
	children: React.ReactNode;
}) {
	// useGA('G-6SJJK8HBGX');
	// useFB('541772133741323');
	// useTikTok('CCKU3N3C77UDI0MAFDQG');
	// useSnap('458447e5-cf73-4faa-96ef-c7b7a49a20c5');

	const documentContext = useContext(DocumentContext);
	const ot7Description =
		"OT7, Overtime's 7x7 football league, puts on full display the electric personalities and insane athleticism of 400-500+ of the best football players in the country. These are the college football stars of tomorrow.";
	const description = (documentContext.description ?? ot7Description).replace(/"/g, '&quot;').replace(/\n/g, '\\n');

	// const absoluteLogoUrl = imgOtePng.startsWith('http')
	// 	? imgOtePng.replace('http://localhost:8080/', 'https://otseven.com/')
	// 	: `https://otseven.com${imgOtePng}`;
	// const imageUrls = Array.isArray(documentContext.image)
	// 	? documentContext.image.length > 0
	// 		? documentContext.image
	// 		: [absoluteLogoUrl]
	// 	: [documentContext.image ?? absoluteLogoUrl];

	const rawTitle = documentContext.title;
	const title = [rawTitle, 'OT7'].filter(Boolean).join(' | ');

	const color = documentContext.color ?? styles.COLOR.BRAND_PRIMARY;

	const canonicalUrl = `https://otseven.com${documentContext.canonicalPath ?? documentContext.location.pathname}${
		documentContext.location.search
	}`;

	useEffect(() => {
		//Mobile browsers lie about vh so we're stuck with this nonsense
		window.onresize = () => document.documentElement.style.setProperty('--app-height', `${window.innerHeight}px`);
		window.onresize = () => {
			//All this is necessary to handle scrollbar width problems
			document.documentElement.style.setProperty('--app-width', `${document.body.clientWidth}px`);
		};
		const observer = new ResizeObserver((_el) => window.onresize(null));
		observer.observe(document.body);

		window.onresize(null);
		return () => {
			window.onresize = null;
			observer.unobserve(document.body);
		};
	}, []);

	//Allow for adjusting letter spacing to help with font jank
	//Not currently working well
	// useEffect(() => {
	// 	document.fonts.ready.then(async (fonts) => {
	// 		fonts.onloadingdone = () => {
	// 			document.body.classList.add('font-loaded');
	// 		};
	// 	});
	// }, []);

	return (
		<html lang="en">
			<head>
				<title>{title}</title>
				<meta name="viewport" content="width=device-width, initial-scale=1" />
				<meta charSet="UTF-8" />
				<meta name="google-site-verification" content="_Npfn_L-weRdy7khF5vLyZVtGDv7o59P15vRfRpuFDw" />
				<meta name="google-site-verification" content="2kA3U3XEwCVR8zHoKOR9o12DITn1nJApUIJqQ1CKH-s" />
				<meta name="google-site-verification" content="AKheGLF0n028K_um_R3PAQHuImh2e_WEe3RXqzo5NaA" />
				<meta property="fb:app_id" content={config('OVERTIME_FB_APP')} />
				<meta property="og:site_name" content="OT7" />
				<meta property="og:title" content={rawTitle} />
				<meta property="og:description" content={description} />
				{/* {imageUrls.map((i) => (
					<meta property="og:image" key={i} content={i} />
				))} */}
				<meta property="og:url" content={canonicalUrl} />
				<meta property="og:type" content="website" />
				<meta name="description" content={description} />
				<link rel="apple-touch-icon" sizes="180x180" href={imgAppleTouchIconPng} />
				<link rel="icon" type="image/svg+xml" href={imgFaviconSvg} />
				<link rel="icon" type="image/png" sizes="32x32" href={imgFavicon_32x32Png} />
				<link rel="icon" type="image/png" sizes="16x16" href={imgFavicon_16x16Png} />
				{/* <link rel="manifest" href="/site.webmanifest" /> */}
				<link rel="mask-icon" href={imgSafariPinnedTabSvg} color={styles.COLOR.BLACK} />
				<link rel="canonical" href={canonicalUrl} />
				<meta name="msapplication-TileColor" content={'black'} />
				<meta name="theme-color" content={'black'} />
				<script
					type="application/ld+json"
					dangerouslySetInnerHTML={{
						__html: JSON.stringify({
							'@context': 'http://schema.org',
							'@type': 'Organization',
							name: 'OT7',
							description: ot7Description,
							url: 'https://otseven.com',
							// logo: absoluteLogoUrl,
							parentOrganization: {
								name: 'Overtime',
								url: 'https://overtime.tv',
							},
							sameAs: [],
						}),
					}}
				/>
				<meta httpEquiv="X-UA-Compatible" content="IE=edge,chrome=1" />
				<style>{`
					
				`}</style>
				<link rel="preconnect" href="https://fonts.googleapis.com" />
				<link rel="preconnect" href="https://fonts.gstatic.com" />
				<link
					rel="preload"
					href="https://fonts.gstatic.com/s/blackopsone/v20/qWcsB6-ypo7xBdr6Xshe96H3aDvbtxsis4I.woff2"
					as="font"
					type="font/woff2"
					crossOrigin="anonymous"
				/>
				<link rel="stylesheet" href="/public/fortawesome.css" />
				<link rel="stylesheet" href="/public/app.css" />
				{Object.values(assets)
					.filter((a) => a.endsWith('css'))
					.map((a) => (
						<link rel="stylesheet" key={a} href={a} />
					))}
			</head>
			<body>
				<div id="root">{children}</div>
				<CookieConsent googleMeasurementId="G-0F7ZSDE6BR" />
				<script
					dangerouslySetInnerHTML={{
						__html: `window.assetManifest = ${JSON.stringify(assets)};window.fetch = window.fetch.bind(window);`,
					}}
				/>
				<script dangerouslySetInnerHTML={{ __html: `window._documentContext = ${JSON.stringify(documentContext)};` }} />
				{Object.values(assets)
					.filter((a) => a.endsWith('js'))
					.map((a) => (
						<script key={a} src={a} />
					))}
				<script src="/public/app.js" />
				{/* <script type="text/javascript" src="https://vivenu.com/web/deliver/js/v1/embed.js" /> */}
			</body>
		</html>
	);
}

// export default Html;
