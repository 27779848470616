import React from 'react';
import Button from '~/src/components/Button';
import Eyebrow from '~/src/components/Eyebrow';
import Html from '~/src/components/Html';
import Event from '~/src/components/Index/Event';
import Game from '~/src/components/Index/Game';
import Games from '~/src/components/Index/Games';
import PageHeader from '~/src/components/PageHeader';
import TBD from '~/src/components/TBD';
import Table from '~/src/components/Table';
import Tabs from '~/src/components/Tabs';
import TeamsLinks from '~/src/components/Teams/Links';
import Logo from '~/src/components/Teams/Logo';
import Picture from '~/src/overtime-lib/src/www/components/Picture';

/*
Add all shared elements here to fix Parcel being dumb
This component should never actually be used
*/

const Common = () => (
	<>
		{/* Parcel is trash and doesn't bundle CSS for components inside contexts */}
		<Html assets={{}} children={''} />
		<PageHeader>Header</PageHeader>
		<Button href={undefined}>Hi</Button>
		<Tabs links={[]} root={''} color={''} />
		<Table headers={[]} currentHeader={''} rows={[]} />
		<Eyebrow>Hi</Eyebrow>
		<TBD />
		<TeamsLinks team={undefined} />
		<Games />
		<Game game={undefined} />
		<Event event={undefined} />
		<Picture />
		<Logo team={undefined} />
	</>
);

export default Common;
