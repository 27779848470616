{
	"COLOR": {
		"BRAND_PRIMARY": "#C3FA02",
		"BRAND_PRIMARY_10": "#F6FFD9",
		"BRAND_PRIMARY_20": "#ECFEAE",
		"BRAND_PRIMARY_30": "#DCFD67",
		"BRAND_SECONDARY": "#46928F",
		"BRAND_LINK": "#46928F",
		"BRAND_PRIMARY_DARK": "#3E5001",
		"OFFBLACK": "#333",
		"BLACK": "#1a1a1a",
		"WHITE": "#ffffff",
		"GRAY": "#878787",
		"DARKGRAY": "#1C1E21",
		"LIGHTGRAY": "#cccccc",
		"OFFWHITE": "#fafafa",
		"SHADOW": "rgba(0,0,0,0.25)",
		"INNER_SHADOW": "rgba(0,0,0,0.05)",
		"AMAZON_BLUE": "#1A98FF",
		"YOUTUBE_RED": "#ff0000"
	},
	"FONT": {
		"DISPLAY_XL": "64px/1.2 'Black Ops One', impact",
		"DISPLAY_L": "56px/1.2 'Black Ops One', impact",
		"DISPLAY_M": "40px/1.2 'Black Ops One', impact",
		"DISPLAY_S": "24px/1.2 'Black Ops One', impact",
		"DISPLAY_XS": "18px/1.2 'Black Ops One', impact",
		"HEADLINE_L": "300 40px/1.2 'Roboto Flex', arial",
		"HEADLINE_M": "300 32px/1.3 'Roboto Flex', arial",
		"HEADLINE_S": "300 24px/1.3 'Roboto Flex', arial",
		"HEADLINE_XS": "300 16px/1.3 'Roboto Flex', arial",
		"TITLE_L": "600 24px/1.33 'Roboto Flex', arial",
		"TITLE_S": "600 18px/1.33 'Roboto Flex', arial",
		"BODY_L_SB": "600 24px/1.5 'Roboto Flex', arial",
		"BODY_L_R": "400 24px/1.5 'Roboto Flex', arial",
		"BODY_M_SB": "600 18px/1.5 'Roboto Flex', arial",
		"BODY_M_R": "400 18px/1.5 'Roboto Flex', arial",
		"BODY_S_SB": "600 16px/1.5 'Roboto Flex', arial",
		"BODY_S_R": "400 16px/1.5 'Roboto Flex', arial",
		"BODY_XS_B": "700 12px/1.5 'Roboto Flex', arial",
		"BODY_XS_R": "400 12px/1.5 'Roboto Flex', arial",
		"NUMBER_L": "24px/1.33 'Black Ops One', impact",
		"NUMBER_S": "18px/1.33 'Black Ops One', impact",
		"EYEBROW": "12px/1.33 'Black Ops One', impact",
		"BUTTON": "600 14px/16px 'Roboto Flex', arial"
	},
	"SIZE": {
		"GAP": "var(--gap)",
		"GAP_QUARTER": "calc(var(--gap) / 4)",
		"GAP_HALF": "calc(var(--gap) / 2)",
		"GAP_2X": "calc(var(--gap) * 2)",
		"GAP_3X": "calc(var(--gap) * 3)",
		"GAP_4X": "calc(var(--gap) * 4)",
		"GAP_6X": "calc(var(--gap) * 6)",
		"ROW_GAP": "var(--row-gap)",
		"LARGE_MOBILE_WIDTH": "640px",
		"TABLET_WIDTH": "768px",
		"DESKTOP_WIDTH": "990px",
		"CONTENT_WIDTH": "calc(1366px - var(--size-gap) * 2)"
	}
}
