import _ from 'lodash';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { capitalize } from '~/src/lib';
import styles from '~/src/lib/styles.json';
import './Tabs.scss';

const Link = ({
	color,
	currentSection,
	section,
	label,
	index,
	root,
	useHash,
	onClick,
}: {
	color: string;
	section: string;
	currentSection: string;
	label: string;
	index: number;
	root: string;
	useHash: boolean;
	onClick?: React.MouseEventHandler<HTMLAnchorElement>;
}) => {
	const isCurrent =
		(index === 0 && _.isEmpty(currentSection)) || currentSection === section || section.includes(`${currentSection}/`);
	return (
		<a
			className={isCurrent ? 'current' : ''}
			style={{ borderBottomColor: isCurrent ? color : undefined }}
			href={`${root}${section ? `${useHash ? '#' : '/'}${section}` : ''}`}
			onClick={onClick}
		>
			{capitalize(label)}
		</a>
	);
};

const Tabs = ({
	links,
	root = '',
	color = styles.COLOR.BRAND_PRIMARY,
	useHash = false,
	useCallback,
	section,
}: {
	links: (string | string[])[];
	root?: string;
	color?: string;
	useHash?: boolean;
	useCallback?: (v: string) => void;
	section?: string;
}) => {
	const intialSection = section ? section : useHash ? useLocation().hash.slice(1) : useParams().section;
	const ref = useRef<HTMLDivElement>();

	const [currentSection, setCurrentSection] = useState<string>(useHash ? null : intialSection);
	useEffect(() => {
		setCurrentSection(intialSection);
	}, [intialSection]);

	//Avoid React SSR throwing a warning
	if (typeof window !== 'undefined') {
		useLayoutEffect(() => {
			const nav = ref.current;
			if (!nav) {
				return;
			}
			const first = nav.firstChild as HTMLAnchorElement;
			const current = nav.getElementsByClassName('current')[0] as HTMLAnchorElement;
			if (!first || !current) {
				return;
			}

			if (
				current.offsetLeft + 8 - nav.scrollLeft < 0 ||
				current.offsetLeft + 8 - nav.scrollLeft + current.offsetWidth > nav.getBoundingClientRect().width
			) {
				nav.scrollTo(current.offsetLeft - first.offsetLeft, 0);
			}
		}, [links, currentSection]);
	}

	return (
		<div className="Tabs">
			<nav className="TabsLinks" ref={ref}>
				{links
					.map((s) => (Array.isArray(s) ? s : [s, s]))
					.map((s, i) => (
						<Link
							key={s[0]}
							section={s[0]}
							label={s[1]}
							index={i}
							currentSection={currentSection}
							color={color}
							root={root}
							useHash={useHash}
							onClick={
								useCallback
									? (e) => {
											e.preventDefault();
											useCallback(s[0]);
										}
									: undefined
							}
						/>
					))}
			</nav>
		</div>
	);
};
export default Tabs;
